.wrapper {
  color: #fff;
  text-align: center;
  padding: 18px 9px;
}

.arrowUp {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.buttonText {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
