:global {
  /*
  // FINDING-6391 due to the way the search/browse price facet "TO" text
  // is positioned, it overlaps the actual input boxes. the input boxes
  // themselves cannot be set to $nav-utils-wrap-z-index otherwise the
  // they will go over the scrolled fix nav. The text and input
  // boxes cannot be on the same z-index otherwise the input boxes
  // cannot receive focus in the middle of the input box
  // if z-index is -1, it will hide the "TO" text beneath the facet
  */
}
:global html,
:global body {
  margin: 0;
}
:global body {
  overflow-x: hidden;
  width: 100%;
  box-sizing: content-box;
}
:global .link {
  color: #000;
  text-decoration: underline;
}
:global h2,
:global .h2 {
  font-size: 22px;
  line-height: 1.2;
  margin: 50px 0 12px;
}
:global .no-margin {
  margin: 0;
}
:global .is-hidden {
  display: none !important;
}
:global .is-invisible {
  visibility: hidden;
}
:global .w-100p {
  width: 100%;
}
:global .abf-container {
  padding-bottom: 50px;
}
:global .abf-container.has-border {
  border-bottom: 1px solid #ddd;
}
:global .boxSizingWrapper {
  box-sizing: border-box;
}
:global .boxSizingWrapper *,
:global .boxSizingWrapper *::before,
:global .boxSizingWrapper *::after {
  box-sizing: inherit;
}