.container {
  background-color: #f6f3eb;
  align-items: center;
  width: 100vw;
  margin: 9px 0;
  padding: 9px 18px;
  display: flex;
  position: relative;
  left: calc(-50vw + 50%);
}

.messageWrapper {
  padding-right: 18px;
  display: block;
}
