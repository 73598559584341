.item {
  align-self: stretch;
}

.list {
  padding-left: 9px;
  margin-right: -9px;
}

.wrapper {
  margin-top: 18px;
}

.tile {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
  border: 1px solid #ddd;
  padding: 9px;
}

.imageWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 75px;
  height: 75px;
  margin-bottom: 6px;
}

.image {
  max-width: 100%;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  color: #222;
  text-align: center;
  word-break: break-word;
  margin: 0;
}