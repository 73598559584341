.wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.content {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
}

.seeMore {
  text-align: center;
  letter-spacing: -.5px;
  margin: 18px 0;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.chairLamp {
  width: 65px;
  height: 65px;
}

.browseMoreButton {
  z-index: 10;
}
