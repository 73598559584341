/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.container {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  align-items: center;
  background-color: #f6f3eb;
  display: flex;
  padding: 9px 18px;
  margin: 9px 0;
}

.messageWrapper {
  display: block;
  padding-right: 18px;
}