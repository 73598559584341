.nullSearchResultsContainer {
  margin-top: 20px;
}

.shopByRoomTitle {
  text-align: center;
}

.box {
  display: block;
  position: relative;
}

.title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 25px;
  color: #fff;
}

.l1Box {
  margin-bottom: 20px;
}
.l1Box .title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
}

.creatorsWrapper {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.creatorBox {
  margin-bottom: 1px;
  transform-style: preserve-3d;
}
.creatorBox .title {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  top: 50%;
  transform: translateY(-50%);
}

.roomBox {
  display: inline-block;
  width: 47%;
  margin-bottom: 5%;
}
.roomBox:nth-child(odd) {
  float: right;
}
.roomBox .title {
  font-size: 14px;
  font-weight: 300;
  color: #222;
  top: 15px;
}

.image {
  max-width: 100%;
  display: block;
}

.l1Box .placeholder {
  padding-top: 75.5%;
}
.creatorBox .placeholder {
  padding-top: 27%;
}
.roomBox .placeholder {
  padding-top: 65%;
}