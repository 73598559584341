.wrapper {
  border-top: 9px solid #f3f3f3;
  width: 100vw;
  padding: 18px 18px 27px;
  position: relative;
  left: calc(-50vw + 50%);
}

.relatedSearchContainer {
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  margin-top: 9px;
  position: relative;
  left: calc(-50vw + 50%);
  overflow: scroll hidden;
}

.relatedSearchContainer::-webkit-scrollbar {
  display: none;
}

.relatedSearchContent {
  width: fit-content;
  padding-left: 9px;
  display: flex;
}

.viewMoreButton {
  margin-top: 27px;
}
