.swiperWrapper {
  width: 100vw;
  margin-top: 18px;
  position: relative;
  left: calc(-50vw + 50%);
}

.swiperItem {
  padding: 0 9px 0 0;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 220px;
}

.link, .lazyLoad {
  width: 100%;
  height: 100%;
}

.link {
  position: relative;
}
