:is(:global html, :global body) {
  margin: 0;
}

:global body {
  box-sizing: content-box;
  width: 100%;
  overflow-x: hidden;
}

:global .link {
  color: #000;
  text-decoration: underline;
}

:global h2 {
  margin: 50px 0 12px;
  font-size: 22px;
  line-height: 1.2;
}

:global .h2 {
  margin: 50px 0 12px;
  font-size: 22px;
  line-height: 1.2;
}

:global .no-margin {
  margin: 0;
}

:global .is-hidden {
  display: none !important;
}

:global .is-invisible {
  visibility: hidden;
}

:global .w-100p {
  width: 100%;
}

:global .abf-container {
  padding-bottom: 50px;
}

:global .abf-container.has-border {
  border-bottom: 1px solid #ddd;
}

:global .boxSizingWrapper {
  box-sizing: border-box;
}

:global .boxSizingWrapper * {
  box-sizing: inherit;
}

:global .boxSizingWrapper :before {
  box-sizing: inherit;
}

:global .boxSizingWrapper :after {
  box-sizing: inherit;
}
