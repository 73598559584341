.item {
  align-self: stretch;
}

.list {
  margin-right: -9px;
  padding-left: 9px;
}

.wrapper {
  margin-top: 18px;
}

.tile {
  border: 1px solid #ddd;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 9px;
  text-decoration: none;
  display: flex;
}

.imageWrapper {
  align-items: center;
  width: 75px;
  height: 75px;
  margin-bottom: 6px;
  display: flex;
  overflow: hidden;
}

.image {
  max-width: 100%;
}

.title {
  color: #222;
  text-align: center;
  word-break: break-word;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
  display: flex;
}
