.bottomModule {
  max-width: 1018px;
  margin: 54px auto 36px;
}

.container {
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding-top: 18px;
  display: flex;
}
