.wrapper {
  padding: 18px 9px;
  color: #fff;
  text-align: center;
}

.arrowUp {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.buttonText {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}