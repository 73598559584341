.description {
  color: #222;
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.description p {
  margin-bottom: 18px;
}
