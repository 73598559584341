.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.justifyFlexStart {
  justify-content: flex-start;
}

.sectionHeader {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-bottom: 0;
}

.article {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
}

.articleElement {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100px;
}

.imgElement {
  composes: articleElement;
}

.featuredImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header {
  margin: 0;
  text-overflow: ellipsis;
  word-break: break-word;
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin-left: 18px;
}
@supports (-webkit-line-clamp: 4) {
  .header {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }
  .header {
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
  }
}

.lazyLoadPlaceholder {
  min-width: 100%;
  min-height: 100%;
  background-color: #fbfbfb;
}

.hideArticle {
  display: none;
}