/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  padding: 18px 18px 27px;
  border-top: 9px solid #f3f3f3;
}

.relatedSearchContainer {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  margin-top: 9px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.relatedSearchContainer::-webkit-scrollbar {
  display: none;
}

.relatedSearchContent {
  padding-left: 9px;
  display: flex;
  width: fit-content;
}

.viewMoreButton {
  margin-top: 27px;
}